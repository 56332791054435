import * as Sentry from '@sentry/gatsby';
import { Integrations } from '@sentry/browser';

// Auxiliary issue threads
// https://github.com/getsentry/sentry-javascript/issues/2074
// https://github.com/codesandbox/codesandbox-client/issues/5406#issuecomment-768339564

// Main issue thread
// https://github.com/getsentry/sentry-javascript/issues/3388
const isAffectByIssue3388 =
  typeof window !== 'undefined'
    ? navigator.userAgent.includes('Chrome/74.0.3729')
    : false;

Sentry.init({
  dsn: process.env.GATSBY_SENTRY_DSN,
  environment: process.env.GATSBY_ACTIVE_ENV,
  integrations(integrations) {
    const newIntegrations = integrations.map((integration) => {
      if (integration.name === 'TryCatch') {
        return new Integrations.TryCatch({
          requestAnimationFrame: !isAffectByIssue3388, // disable raf hook
        });
      }

      return integration;
    });

    return newIntegrations;
  },
});
